<template>
  <div class="container eventDetails pb-16 fadeInFast">
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <HeadlineDetailView
        :icon="'mdi-calendar'"
        :headline="eventDetail.name"
        :entity="'Termin'"
    >
    </HeadlineDetailView>
    <v-card flat class="detailCard rounded-xl pa-4 mt-6">
      <v-tabs class="mb-12">
          <Tab
              :tabs="['Allgemein']"
              :register="register"
              @changed="register=$event"
          >
          </Tab>
      </v-tabs>
      <div class="row pa-4" v-if="register===0">
        <div class="col-12 col-sm-7">
          <KeyValueBlock
              :title="''"
              :key-values="[
              {title: 'Name', value: eventDetail.name, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'events',
                id: eventDetail.id,
                row: 'name'
              }},
               {title: 'Start', value: getStringDate(eventDetail.start), isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'events',
                id: eventDetail.id,
                row: 'start',
                type: 'date',
                oldValue: eventDetail.start
              }},
              {title: 'Uhrzeit (Start)',
               value: eventDetail.timeStart,
               isEdit: false,
               editBtn:{
                icon:'mdi-check-circle',
                entity: 'events',
                id: eventDetail.id,
                row: 'timeStart',
                type: 'time',
                oldValue: eventDetail.timeStart

              }},
               {
                title: 'Ende',
                value: getStringDate(eventDetail.end),
                isEdit: false,
                editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'events',
                  id: eventDetail.id,
                  row: 'end',
                  type: 'date',
                  oldValue: eventDetail.end
                }
              },
              {
                title: 'Uhrzeit (Ende)',
                value: eventDetail.timeEnd,
                isEdit: false,
                editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'events',
                  id: eventDetail.id,
                  row: 'timeEnd',
                  type: 'time',
                  oldValue: eventDetail.timeEnd
                }
              },
              {title: 'Details',
               value: eventDetail.details,
               isEdit: false,
               editBtn:{
                icon:'mdi-check-circle',
                entity: 'events',
                id: eventDetail.id,
                row: 'details'
              }},
              ]"
              @editEntry="editEntry"
          >
          </KeyValueBlock>
          <CustomFieldBlock :entity="'event'" :user="user" :entryId="eventDetail.id"/>
        </div>
        <div class="col-12 col-sm-5 ">
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>

import {mapGetters} from "vuex"
import {date2String, error, msgObj} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const KeyValueBlock = () => import("@/components/detailView/KeyValueBlock");
const HeadlineDetailView = () => import("@/components/detailView/HeadlineDetailView");
const CustomFieldBlock = () => import("@/components/detailView/CustomFieldBlock");
const Tab = () => import("@/components/generalUI/Tab");

export default {
  name: 'EventDetail',
  components: {
    HeadlineDetailView,
    KeyValueBlock,
    Message,
    CustomFieldBlock,
    Tab
  },
  mounted() {
    window.scrollTo(0, 0)

    this.$store.dispatch('calendar/getEvent', {
      uid: this.user.id,
      eventId: this.$route.params.id
    }).catch((err)=>{
      this.message = error(err)
      setTimeout(() => this.message.show = false, 5000);
    })

  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),

    ...mapGetters('calendar', {
      eventDetail: 'event',
    }),
  },
  data() {
    return {
      dialog: false,
      register: 0,
      message: msgObj(),
    }
  },
  methods: {
    getStringDate(date) {
      return date2String(date)
    },
    editEntry(payload) {
      console.log(payload)
      this.$store.dispatch('customer/editField', {
        entity: "events",
        uid: this.user.id,
        id: payload.id,
        newValue: payload.newValue,
        row: payload.row
      }).then(() =>{
        this.$store.dispatch('calendar/getEvent', {
          eventId: payload.id
        })
      }).catch((err)=>{
        this.message = error(err)
      })
    }
  }
}
</script>
<style>


</style>
