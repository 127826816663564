var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container eventDetails pb-16 fadeInFast"},[_c('Message',{attrs:{"message":_vm.message},on:{"closeAlert":function($event){_vm.message.show = false}}}),_c('HeadlineDetailView',{attrs:{"icon":'mdi-calendar',"headline":_vm.eventDetail.name,"entity":'Termin'}}),_c('v-card',{staticClass:"detailCard rounded-xl pa-4 mt-6",attrs:{"flat":""}},[_c('v-tabs',{staticClass:"mb-12"},[_c('Tab',{attrs:{"tabs":['Allgemein'],"register":_vm.register},on:{"changed":function($event){_vm.register=$event}}})],1),(_vm.register===0)?_c('div',{staticClass:"row pa-4"},[_c('div',{staticClass:"col-12 col-sm-7"},[_c('KeyValueBlock',{attrs:{"title":'',"key-values":[
            {title: 'Name', value: _vm.eventDetail.name, isEdit: false, editBtn:{
              icon:'mdi-check-circle',
              entity: 'events',
              id: _vm.eventDetail.id,
              row: 'name'
            }},
             {title: 'Start', value: _vm.getStringDate(_vm.eventDetail.start), isEdit: false, editBtn:{
              icon:'mdi-check-circle',
              entity: 'events',
              id: _vm.eventDetail.id,
              row: 'start',
              type: 'date',
              oldValue: _vm.eventDetail.start
            }},
            {title: 'Uhrzeit (Start)',
             value: _vm.eventDetail.timeStart,
             isEdit: false,
             editBtn:{
              icon:'mdi-check-circle',
              entity: 'events',
              id: _vm.eventDetail.id,
              row: 'timeStart',
              type: 'time',
              oldValue: _vm.eventDetail.timeStart

            }},
             {
              title: 'Ende',
              value: _vm.getStringDate(_vm.eventDetail.end),
              isEdit: false,
              editBtn:{
                icon:'mdi-check-circle',
                entity: 'events',
                id: _vm.eventDetail.id,
                row: 'end',
                type: 'date',
                oldValue: _vm.eventDetail.end
              }
            },
            {
              title: 'Uhrzeit (Ende)',
              value: _vm.eventDetail.timeEnd,
              isEdit: false,
              editBtn:{
                icon:'mdi-check-circle',
                entity: 'events',
                id: _vm.eventDetail.id,
                row: 'timeEnd',
                type: 'time',
                oldValue: _vm.eventDetail.timeEnd
              }
            },
            {title: 'Details',
             value: _vm.eventDetail.details,
             isEdit: false,
             editBtn:{
              icon:'mdi-check-circle',
              entity: 'events',
              id: _vm.eventDetail.id,
              row: 'details'
            }} ]},on:{"editEntry":_vm.editEntry}}),_c('CustomFieldBlock',{attrs:{"entity":'event',"user":_vm.user,"entryId":_vm.eventDetail.id}})],1),_c('div',{staticClass:"col-12 col-sm-5"})]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }